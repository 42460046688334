<template>
  <div class="d-flex justify-center mb-8">
    <div class="insight-left rounded-l-x d-flex align-center">
      <div class="text-overline">insight</div>
    </div>
    <div class="insight-right rounded-r-xl d-flex align-center">
      <div class="text-body-2">{{ details.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["details"]
};
</script>

<style lang="scss" scoped>
.insight-left {
  background-color: #7b2a57;
  display: inline-block;
  padding: 0 20px;
  color: white;
}

.insight-right {
  background-color: #c83687;
  display: inline-block;
  padding: 0 20px;
  color: white;
  font-size: 0.8em;
}
</style>
